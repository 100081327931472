<template>
  <Menu as="div" v-slot="{ close }" class="relative ml-2 mx-2 sm:flex-shrink-0">
    <MenuButton v-if="brandconfig.LANGUAGE_OPTION" class="relative flex rounded-full text-sm">
      <CountryFlag :code="LOCALES[$i18n.locale].flag" class="rounded-full h-5" />
    </MenuButton>
    <MenuItems
      class="fixed w-full h-auto mt-5 sm:w-auto sm:h-auto sm:absolute sm:flex flex-col right-0 z-20 sm:mt-2 sm:pb-1 origin-top-right sm:rounded-md bg-background text-forecolor sm:shadow-lg sm:ring-1 sm:ring-black sm:ring-opacity-5 overflow-hidden focus:outline-none">
      <div class="flex justify-between">
        <h3 class="text-xl font-bold px-4 py-2">{{ $t("Language") }}</h3>
        <button class="sm:hidden mr-5" @click="close">
          <XMarkIcon class="h-5" />
        </button>
      </div>
      <hr />
      <div class="py-2">
        <MenuItem :key="locale" v-for="locale of locales" v-slot="{ active }">
          <button @click="switchLocale(locale)" class="w-full" :class="{ 'bg-primary': active }">
            <div class="px-4 py-2 text-left flex min-w-max justify-between gap-2 items-center">
              <CountryFlag :code="LOCALES[locale]?.flag" />
              <span class="grow">{{ LOCALES[locale]?.title }}</span>
              <CheckIcon class="w-5" v-if="$i18n.locale === locale" />
            </div>
          </button>
        </MenuItem>
      </div>
    </MenuItems>
  </Menu>
</template>

<script setup>
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { CheckIcon, XMarkIcon } from "@heroicons/vue/24/outline";
import brandconfig from "@/brandconfig";
import CountryFlag from "@/components/CountryFlag.vue";
import { LOCALES, locales, setLocale } from "./locales.js";
import { useUserStore } from "@/store/user.js";
import { i18n } from "../../../i18n.js";
const userStore = useUserStore();

const switchLocale = (option) => {
  setLocale((i18n.locale = option));
};
</script>
