import "@/style.css";
import "@xs2event/js-lib/style";
// -------------------------------

import App from "@/App.vue";
import { createApp } from "vue";
import pinia from "@/store.js";
import { useUserStore } from "@/store/user";
import { newRouter } from "@/router.js";
import VueGtag from "vue-gtag";
import { i18n } from "./i18n";
import { initializeSentry } from "./helpers/sentry/sentry.js";
import brandconfig from "@/brandconfig.js";
import config from "./config";
import { VueQueryPlugin } from "@tanstack/vue-query";

// -------------------------------

const portalUser = brandconfig.PORTAL_USER;
const app = createApp(App);
app.use(i18n);
app.use(pinia);
app.use(VueQueryPlugin);

useUserStore();
const router = newRouter();

initializeSentry(app, router); // preferred way of init

app.use(
  {
    defaultName: window.location.href,
    defaultLocale: i18n.global.locale.value,
    locales: brandconfig.locales,
    preconnect: [config.CDN_URL, config.API_URL],
  },
  router
);
app.use(router);

if (brandconfig.GA_ID !== "") {
  app.use(VueGtag, {
    config: { id: brandconfig.GA_ID },
    router,
    enabled: true,
  });
  app.provide("gtag", app.config.globalProperties.$gtag);
}

app.mount("#app");
