<template>
  <Page>
    <Header />
    <BookingorderRow
      v-if="isLoaded"
      :bookingOrder="order"
      :index="idx"
      :totalItems="bookingsStore?.bookingorders?.length"
      v-for="(order, idx) in bookingsStore.bookingorders" />
    <Footer />
  </Page>
</template>

<script setup>
import { Header, Footer, BookingorderRow } from "@/components/xs2ticket";
import { useBookingsStore } from "@/store/bookings.js";
import { useUserStore } from "@/store/user.js";
import { onMounted, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import Page from "../components/page/Page.vue";

const router = useRouter();
const bookingsStore = useBookingsStore();
const userStore = useUserStore();
const events = reactive({});
const isLoaded = ref(false);

onMounted(async () => {
  await bookingsStore.getTicketsForBranded(localStorage.booking_id);
  if (bookingsStore?.bookingorders?.length > 0) {
    await userStore.fetchDistributor(bookingsStore?.bookingorders[0].distributor_id);
    isLoaded.value = true;
  }
});
</script>
