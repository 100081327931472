import { defineStore } from "pinia";
import axios from "axios";
import { api } from "@/helpers/api.js";
import { useRouter } from "vue-router";
import { config } from "@/config";
import brandconfig from "../brandconfig.js";

const router = useRouter();

const INITIAL_STATE = () => ({
  // signIn
  username: null,
  roles: [],

  isAdmin: false,
  isDistributor: false,
  isPremium: false,
  isSupplier: false,
  isUser: false,

  client_id: null,

  // distributor
  _distributor: null,
  distributor_id: null,
  distributor_name: null,
  distributor_status: null,
  grace_period_download_tickets: null,

  supplier_id: null,

  member_state: null,
  account_type: null,

  // misc
  authPending: false,
  authFailed: false,
  authCompleted: null,

  expiryDate: null,

  bookingId: null, // download xs2ticket

  // FX rates
  fxRates: null,
  selectedCurrency: !!localStorage.getItem("fxRate") ? localStorage.getItem("fxRate") : "EUR",

  isAuthenticated: false,
});

export const useUserStore = defineStore("user", {
  state: INITIAL_STATE,
  getters: {
    distributor() {
      if (!this._distributor) {
        this._distributor = {};
      }
      return this._distributor;
    },
  },
  actions: {
    fetchDistributor(id) {
      return api.get("/distributors/ticketportal/" + id).then((res) => {
        this._distributor = res.data;
      });
    },

    async authenticate(endpoint, Authorization, additionalAuthorizationData = undefined) {
      this.authPending = true;
      this.authCompleted = new Promise(async (resolve, reject) => {
        const headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
          version: config.VERSION,
          Authorization,
        };
        try {
          const req = axios.post(api.defaults.baseURL + endpoint, additionalAuthorizationData || {}, { headers });
          const res = await req;
          this.handleAuthentication(res.data);
          resolve(res);
        } catch (err) {
          console.debug(err);
          reject(new Error("authorization failed"));
        }
      });
      return await this.authCompleted;
    },

    async signOut() {
      delete localStorage.Authorization;
      delete localStorage.refresh_token;
      delete localStorage.booking_id;
      delete localStorage.brand;
      location.reload();
    },

    async refresh() {
      if (localStorage.refresh_token) {
        const Authorization = "Bearer " + localStorage.refresh_token;
        try {
          return await this.authenticate("/etickets/login/refresh", Authorization);
        } catch (err) {
          this.authPending = false;
          await this.signOut();
        }
      } else {
        this.authCompleted = Promise.resolve("no refreshToken");
      }
    },

    handleAuthentication(authData) {
      localStorage.Authorization = "Bearer " + authData.access_token;
      this.$patch(authData);
      this.authPending = false;
      this.authFailed = false;
      this.isAuthenticated = true;
    },

    async signIn(payload) {
      const authData = window.btoa(`${payload.bookingcode}:${payload.email}`);
      const headers = { Authorization: `Basic ${authData}` };
      try {
        const request = axios.post(api.defaults.baseURL + "/etickets/login/default", {}, { headers });
        const res = await request;
        localStorage.refresh_token = res.data.refresh_token;
        localStorage.booking_id = res.data.booking_id;
        this.isAuthenticated = true;
        this.handleAuthentication(res.data);
      } catch (err) {
        console.debug(err);
        router.push({ path: "/" });
      }
    },
  },
});

export const initializeUserStore = async () => {
  const portalUser = brandconfig.PORTAL_USER;
  const userStore = useUserStore();

  if (userStore.authCompleted) {
    return;
  }
  if (portalUser) {
    await userStore.signIn(portalUser);
  } else {
    await userStore.refresh();
  }
};
