export default {
  API_URL: "https://api.xs2event.com/v1",
  CDN_URL: "https://cdn.xs2event.com",
  ENV: "production",
  SENTRY_ENABLE: true,
  SENTRY_DSN: "https://5d7944c36794b1b4af8d5604ef0659ec@sentry.xs2event.com/32",
  SENTRY_SAMPLE_RATE: 1.0,
  EVENT_MEDIA_BASE_URL: "https://cdn.xs2event.com/media-production",
  MEDIA_CONTENT_URL: "https://cdn.xs2event.com/mediacontent",
  FLAGS_BASE_URL: "https://cdn.xs2event.com/flags-test",
  EVENT_MEDIA_COVER_PHOTO: "cover-photo",
  VERSION: "d27a8326",
  CLICKY: 101405593,
  HOTJAR: 3357273,
  WEBAPP: "portalv2",
  EMEM: "spa25@emesa.xs2ticket.com",
  SD: "socialdeal",
  EM: "emesa",
  SDEM: "spa25@socialdeal.xs2ticket.com",
  UMJ: "uitmetjonker",
  SDUMJ: "blocked1234@umj.xs2ticket.com",
};
