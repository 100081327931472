<template>
  <img
    class="self-center"
    :src="getFlagSrc(code)"
    v-if="code?.length === 3"
    :class="big ? 'h-8 rounded-full' : 'h-4'" />
</template>
<script setup>
defineProps({ code: { required: true }, big: Boolean });

import config from "@/config";
const getFlagSrc = (code) => `${config.FLAGS_BASE_URL}/${code}.svg`;
</script>
