import { useToast } from "@xs2event/js-lib/toast";
import { useQuery } from "@tanstack/vue-query";
import { watch } from "vue";
import { Button } from "@xs2event/js-lib/components";
import { useI18n } from "vue-i18n";
import { useUserStore } from "@/store/user";
import { api } from "@/helpers/api";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useVersionChecker = () => {
  const { addToast } = useToast();
  const userStore = useUserStore();

  const projectSlug = "xs2ticket-portal-v2";

  const { t } = useI18n();

  const { data: version } = useQuery<string>({
    queryKey: ["version"],
    enabled: () => !!userStore.isAuthenticated,
    queryFn: async () => {
      const response = await api.get(`/webappversions/${projectSlug}/latest`);

      return response.data.webapp_version;
    },
    refetchInterval: 1000 * 60, //Every minute
  });

  watch(version, (newVersion, oldVersion) => {
    if (oldVersion == undefined) return;

    if (oldVersion == newVersion) return;

    addToast({
      title: t("A new version of the app has been released, please refresh the page."),
      content: <Button onClick={() => window.location.reload()}>{t("Refresh page")}</Button>,
    });
  });
};
