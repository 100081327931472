<template>
  <div class="flex gap-2 pb-1 items-center">
    <!--
    <svg class="h-4" viewBox="0 0 420 606">
      <path d="M418.182,303.077L418.316,302.943L302.919,187.558L129.103,13.762C120.569,5.204 108.244,0.085 95.706,0.268C83.03,0.268 70.851,5.174 62.286,13.731L13.837,62.188C-4.569,80.601 -4.569,110.583 13.837,129.012L187.661,302.813L187.791,302.943L13.837,476.905C4.912,485.828 0,497.687 0,510.309C0,522.932 4.912,534.805 13.837,543.729L62.293,592.186C71.21,601.095 83.076,606 95.691,606C108.306,606 120.172,601.095 129.095,592.186L302.927,418.341L418.182,303.077Z" fill="currentColor" />
    </svg>
-->

    <svg class="h-6" viewBox="0 0 566.93 566.93">
      <rect fill="currentColor" y="0" width="566.93" height="566.93" />
      <path
        fill="white"
        d="m420.41,283.43l.08-.08-70.08-70.08h0l-105.56-105.55c-5.18-5.2-12.67-8.31-20.28-8.19-7.7,0-15.09,2.98-20.3,8.18l-29.42,29.43c-11.18,11.18-11.18,29.39,0,40.58l105.57,105.55h0l.08.08-105.65,105.65c-5.42,5.42-8.4,12.62-8.4,20.29s2.98,14.88,8.4,20.3l29.43,29.43c5.42,5.41,12.62,8.39,20.28,8.39s14.87-2.98,20.29-8.39l105.57-105.58h0l70-70h0Z" />
    </svg>

    <svg class="h-4" viewBox="0 -1340 10550 1335">
      <g transform="scale(1,-1)" fill="black">
        <path
          d="m 2332.96,768.336 c -67.01,23.746 -149.22,44.746 -246.66,63.023 -75.55,14.625 -129.74,28.34 -162.63,41.121 -32.89,12.782 -49.34,31.969 -49.34,57.555 0,53.574 55.41,80.405 166.28,80.405 112.05,0 230.82,-31.694 356.3,-95.022 l 129.74,312.442 c -64.58,36.55 -139.18,64.24 -223.84,83.15 -84.67,18.88 -170.84,28.31 -258.54,28.31 -127.91,0 -236.62,-18.88 -326.16,-56.62 -89.54,-37.8 -156.85,-89.56 -201.91,-155.34 -45.07,-65.77 -67.6,-138.856 -67.6,-219.258 0,-92.575 24.35,-165.993 73.09,-220.164 48.71,-54.235 105.97,-93.184 171.75,-116.961 65.78,-23.75 148,-45.985 246.68,-66.684 76.73,-15.828 131.84,-30.453 165.36,-43.844 33.48,-13.406 50.25,-33.527 50.25,-60.301 0,-23.144 -12.8,-40.515 -38.37,-52.089 -25.59,-11.571 -68.24,-17.348 -127.91,-17.348 -69.43,0 -141.92,10.641 -217.44,31.973 -75.55,21.3 -143.74,49.613 -204.64,84.961 L 1428.49,133.391 c 64.55,-40.2191 147.1,-72.1801 247.59,-95.9301 100.49,-23.7812 203.74,-35.65231 309.71,-35.65231 127.91,0 236.62,19.17971 326.16,57.56251 89.54,38.3672 156.83,90.1169 201.9,155.3199 45.05,65.149 67.61,137.961 67.61,218.356 0,92.551 -24.67,165.637 -74,219.266 -49.34,53.57 -107.52,92.269 -174.5,116.023" />
        <path
          d="m 3489.23,531.699 c 86.47,77.949 144.63,147.688 174.49,209.231 29.83,61.488 44.77,127.593 44.77,198.234 0,79.166 -21.93,149.206 -65.77,210.136 -43.86,60.88 -104.79,107.81 -182.74,140.71 -77.97,32.87 -167.49,49.31 -268.59,49.31 -132.78,0 -246.07,-26.5 -339.86,-79.46 -93.81,-53 -165.07,-125.18 -213.77,-216.55 L 2963,880.699 c 18.26,37.739 44.44,66.985 78.56,87.711 34.09,20.699 70.63,31.059 109.63,31.059 40.2,0 70.95,-8.848 92.28,-26.492 21.29,-17.676 31.97,-42.336 31.97,-73.997 0,-25.593 -6.71,-51.785 -20.1,-78.589 -13.41,-26.797 -40.2,-59.094 -80.4,-96.836 L 2712.67,296 V 31.0508 H 3737.72 V 365.43 h -431.21 l 182.72,166.269" />
        <path
          d="m 4328.63,524.371 h 496.09 v 310.633 h -496.09 v 149.828 h 586.23 V 1310.09 H 3904.71 V 31.0391 H 4968.13 V 356.293 h -639.5 v 168.078" />
        <path
          d="m 7081.8,524.371 h 496.11 V 835.004 H 7081.8 v 149.828 h 586.23 V 1310.09 H 6657.89 V 31.0391 H 7721.32 V 356.293 H 7081.8 v 168.078" />
        <path
          d="M 5802.82,562.762 5497.67,1310.1 h -464.1 L 5574.41,31.0508 h 423.93 L 6539.18,1310.1 H 6115.27 L 5802.82,562.762" />
        <path
          d="M 8728.83,729.055 8242.79,1310.1 H 7888.3 V 31.0508 h 420.26 V 612.098 L 8794.6,31.0508 h 354.47 V 1310.1 H 8728.83 V 729.055" />
        <path
          d="M 10447.1,1310.1 H 9316.05 V 975.719 h 408.46 V 31.0508 h 431.19 V 975.719 h 408.5 v 217.331 c 0,64.65 -52.4,117.05 -117.1,117.05" />
        <path
          d="m 905.406,675.715 0.289,0.289 -0.347,0.344 426.072,426.062 -249.55,249.56 L 655.785,925.891 279.52,1302.11 c -18.477,18.53 -45.157,29.61 -72.305,29.21 -27.445,0 -53.813,-10.62 -72.36,-29.14 L 29.957,1197.26 c -39.85153,-39.87 -39.85153,-104.78 0,-144.68 l 376.285,-376.236 -0.281,-0.281 0.34,-0.344 L 29.957,299.359 C 10.6367,280.039 -0.00390625,254.359 -0.00390625,227.031 -0.00390625,199.699 10.6367,174 29.957,154.672 L 134.871,49.7617 c 19.305,-19.2812 45,-29.9023 72.309,-29.9023 27.316,0 53.004,10.6211 72.324,29.9023 L 655.797,426.09 1081.87,0 1081.93,0.0585938 1081.99,0 l 249.57,249.551 -426.162,426.16 0.008,0.004" />
      </g>
    </svg>
  </div>
</template>
