import neutral from "@/config/neutral.js";
import tiqets from "@/config/tiqets.js";
import { useRouter } from "vue-router";
const router = useRouter();

export const isTiqets = window.location.toString().includes("tiqets") || localStorage?.brand === "tiqets";

export const isBranded = isTiqets;

const importInterFont = () => {
  import("@fontsource/inter/200.css");
  import("@fontsource/inter/300.css");
  import("@fontsource/inter/400.css");
  import("@fontsource/inter/500.css");
  import("@fontsource/inter/600.css");
  import("@fontsource/inter/700.css");
};

const importRobotoFont = () => {
  import("@fontsource/roboto-condensed");
  import("@fontsource/roboto-condensed/700.css");
  import("@fontsource/roboto-condensed");
};

const importBarlowFont = () => {
  import("@fontsource/barlow");
  import("@fontsource/barlow/400.css");
  import("@fontsource/barlow-condensed"); // Defaults to weight 400
  import("@fontsource/barlow-condensed/400.css");
  import("@fontsource/barlow-condensed/600.css"); // Specify weight
  import("@fontsource/barlow-condensed/400-italic.css"); // Specify weight and style
};

if (isTiqets) {
  import("@/assets/styles/brand/tiqets.css");
  importRobotoFont();
}

if (!isBranded) {
  import("@/assets/styles/brand/xs2event.css");
  importBarlowFont();
}

const brandconfig = isTiqets ? tiqets : neutral;

brandconfig.ready = import("@/refdata/menu.json");

document.body.className = "theme-" + brandconfig.BRAND;

brandconfig.ready.then((data) => {
  Object.assign(brandconfig, data);
});

export default brandconfig;
