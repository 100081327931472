import { createI18n } from "vue-i18n";

import en from "./locales/en.json";
import nl from "./locales/nl.json";
import de from "./locales/de.json";
import es from "./locales/es.json";
import pt from "./locales/pt.json";
import da from "./locales/da.json";
import se from "./locales/sv.json";
import fi from "./locales/fi.json";
import it from "./locales/it.json";
import fr from "./locales/fr.json";
import zh_Hans from "./locales/zh-Hans.json";
import ar from "./locales/ar.json";
import ja from "./locales/ja.json";
import ko from "./locales/ko.json";

export const i18n = createI18n({
  legacy: false,
  locale: localStorage.AcceptLanguage || navigator.language || "en",
  messages: { en, nl, de, es, pt, da, se, fi, it, fr, zh_Hans, ar, ja, ko },
});
