import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { setApiPath } from "@xs2event/js-lib/api";

import { config } from "@/config";
import { useUserStore } from "@/store/user";

setApiPath(config.API_URL);

const log = (...args) => config.isProduction || console.debug(...args);
const warn = (...args) => config.isProduction || console.warn(...args);

const createApiClient = () => {
  const baseURL = config.API_URL;
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-Webapp": config.WEBAPP,
    "X-Webapp-Version": config.VERSION,
  };
  const client = axios.create({ baseURL, headers });
  client.interceptors.request.use((req) => {
    req.headers["Authorization"] = localStorage.Authorization;
    req.headers["Accept-Language"] = localStorage.AcceptLanguage || "en";
    return req;
  });
  createAuthRefreshInterceptor(client, (req) => {
    const userStore = useUserStore();
    return userStore.refresh();
  });
  const _cache = {};
  const _get = client.get;
  const _ts = {};
  const _trace = {};
  client.get = (url, ...args) => {
    if (url in _ts) {
      const t = Date.now() - _ts[url];
      if (t < 500) {
        const e = new Error();
        warn("api.get (repeated)", t, "ms");
        warn("GET", url);
        warn("TRACE current", e, "TRACE previous", _trace[url]);
      } else {
        warn("api.get (repeated)", t, "ms");
        warn("GET", url);
      }
    }
    _ts[url] = Date.now();
    _trace[url] = new Error();
    return _get.call(client, url, ...args);
  };
  client.purge = (url) => {
    delete _cache[url];
  };
  client.load = client.cache = (url, ...args) => {
    if (url in _cache) {
      if (url in _ts) {
        const t = Date.now() - _ts[url];
        if (t < 500) {
          const e = new Error();
          warn("api.cache (repeated)", t, "ms");
          warn("GET", url);
          warn("TRACE current", e, "TRACE previous", _trace[url]);
        }
      }
      _ts[url] = Date.now();
      _trace[url] = new Error();
      return _cache[url];
    } else {
      log("api.cache", url);
      return (_cache[url] = client.get(url, ...args));
    }
  };
  return client;
};

const createCdnClient = () => {
  const baseURL = config.CDN_URL;
  const client = axios.create({
    baseURL,
  });
  const _cache = {};
  const _get = client.get;
  client.cache = (url, ...args) => (_cache[url] = _cache[url] || _get(url, ...args));
  return client;
};

export const api = createApiClient();
export const cdn = createCdnClient();

export const epibrate = (ms = 1) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
