import { defineStore } from "pinia";
import { CategoriesApi, EventsApi } from "@xs2event/js-lib/api";

const eventsApi = new EventsApi();
const categoriesApi = new CategoriesApi();

export const useEventStore = defineStore("event", {
  state: () => ({
    event: null,
    tickets: [],
    categories: [],
    loading: false,
  }),
  actions: {
    async fetchEvent(eventId) {
      this.loading = true;
      try {
        const res = await eventsApi.getEvent({
          eventId: eventId,
        });
        this.loading = false;
        return (this.event = res);
      } catch (error) {
        this.loading = false;
        throw error;
      }
    },

    async getCategories(categoryIds) {
      return await categoriesApi.getCategories({
        categoryId: categoryIds,
        pageSize: 25,
      });
    },
  },
});
