import { ref } from "vue";
import { i18n } from "@/i18n";
import brandconfig from "@/brandconfig";

export const LOCALES = {
  en: { title: "English", flag: "GBR" },
  de: { title: "Deutsch", flag: "DEU" },
  nl: { title: "Nederlands", flag: "NLD" },
  es: { title: "Español", flag: "ESP" },
  da: { title: "Dansk", flag: "DNK" },
  se: { title: "Swedish", flag: "SWE" },
  it: { title: "Italiano", flag: "ITA" },
  fr: { title: "Français", flag: "FRA" },
  pt: { title: "Português", flag: "PRT" },
  zh_Hans: { title: "Chinese (中文)", flag: "CHN" },
  ko: { title: "Korean (한국인)", flag: "KOR" },
  ja: { title: "Japanese (日本語)", flag: "JPN" },
  ar: { title: "Arabic (عربي)", flag: "SAU" },
};

export const locales = ref(Object.keys(LOCALES));

export const setLocale = (lang) => {
  localStorage.AcceptLanguage = lang;
  i18n.global.locale.value = lang;
};

export const getLocale = () => {
  if (localStorage.getItem("AcceptLanguage") !== null) {
    return localStorage.AcceptLanguage;
  } else {
    return "en";
  }
};

const initLocales = ({ lang = Object.keys(LOCALES) }) => {
  if (!localStorage.getItem("AcceptLanguage")) setLocale((i18n.locale = brandconfig.DEFAULT_LANGUAGE));
};

brandconfig.ready.then(initLocales);
