export default {
  API_URL: "https://testapi.xs2event.com/v1",
  CDN_URL: "https://cdn.xs2event.com",
  XS2TICKET: "https://test33.xs2ticket.com/download",
  ENV: "test",
  SENTRY_ENABLE: false,
  SENTRY_DSN: "",
  SENTRY_SAMPLE_RATE: 1.0,
  EVENT_MEDIA_BASE_URL: "https://cdn.xs2event.com/media-production",
  MEDIA_CONTENT_URL: "https://cdn.xs2event.com/mediacontent-test",
  FLAGS_BASE_URL: "https://cdn.xs2event.com/flags-test",
  EVENT_MEDIA_COVER_PHOTO: "cover-photo",
  FORMULA_ONE_MEDIA_COVER_PHOTO: "https://cdn.xs2event.com/events-test/formula-1/cover-photo",
  VERSION: "d27a8326",
  CLICKY: 101405593,
  HOTJAR: 3357273,
  WEBAPP: "portalv2",
  EMEM: "emesa@emesa.xs2ticket.com",
  SD: "socialdeal",
  EM: "emesa",
  SDEM: "spa24@socialdeal.xs2ticket.com",
  UMJ: "uitmetjonker",
  SDUMJ: "spa22@umj.xs2ticket.com",
};
