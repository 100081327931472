export default {
  BRAND: "tiqets",
  BRAND_TITLE: "Tiqets",
  LOGO: "https://cdn.xs2event.com/logos/tiqets.png",
  LOGO_MOBILE: "https://cdn.xs2event.com/logos/tiqets.png",
  LOGO_FOOTER: "https://cdn.xs2event.com/logos/tiqets.png",
  LANGUAGE_OPTION: true,
  CURRENCY_OPTION: true,
  MOBILE_ONLY: false,
  DEFAULT_LANGUAGE: "en",
  TERMS: "terms/",
  GENERAL_TERMS_LINK: "https://cdn.xs2event.com/generalterms/general-terms-XS2Event_v1.4.pdf",
  GA_ID: "G-J6SHTJESVL",
};
