import { defineStore } from "pinia";
import { api } from "@/helpers/api.js";
import { startDownload } from "../helpers/download.js";

export const useBookingsStore = defineStore("bookings", {
  state: () => ({
    bookings: [],
    bookingorders: [],
    bookingorder_filter_bookings: [],
    pager: {
      page: 1,
      page_size: 25,
      total_size: 0,
      total_pages: 0,
    },
    query: {
      sorting: "created:desc",
    },
    bookingorder: null,
  }),
  actions: {
    downloadTicketsAsZip(payload) {
      const { bookingOrderId, fileName } = payload;
      let uri = `etickets/download/zip/${bookingOrderId}`;
      return api
        .get(uri)
        .then((res) => {
          startDownload(res.data, `tickets-${fileName.toLowerCase()}.zip`);
        })
        .catch((e) => {
          throw e;
        });
    },

    downloadSingleTicket(payload) {
      const { bookingorderId, orderItemId, url, dayIdx } = payload;
      if (!!url) {
        if (url.toLowerCase().endsWith("pkpass")) {
          const getUri = `etickets/download/pkpass/${bookingorderId}/${orderItemId}/url/${url}`;
          return api
            .get(getUri)
            .then((res) => {
              startDownload(url);
            })
            .catch((e) => {
              throw e;
            });
        } else {
          const seconds = Math.floor(Date.now() / 1000);
          const getUri = `etickets/download/${bookingorderId}/${orderItemId}/url/${url}?key=${seconds}`;
          return api
            .get(getUri, { responseType: "blob" })
            .then((res) => {
              const url = window.URL.createObjectURL(res.data);
              startDownload(url, `ticket-${orderItemId}`);
            })
            .catch((e) => {
              throw e;
            });
        }
      }
    },

    async getTicketsForBranded(bookingId) {
      const req = api.get(`bookingorders/restricted/${bookingId}`);
      const res = await req;
      this.bookingorders = res.data.bookingorders;
    },

    saveEmail(payload) {
      return api
        .post(`bookingorders/restricted/${payload.bookingorderId}/notify`, payload)
        .then((res) => {
          return res;
        })
        .catch((err) => {
          throw err;
        });
    },
  },
});
