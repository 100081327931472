<template>
  <p class="mt-4 mb-20 text-center" v-if="!!userStore?._distributor?.customerservice.email">
    {{ $t("Questions about your tickets? Please contact") }}
    <a class="underline" :href="`mailto:${userStore._distributor?.customerservice.email}`">{{
      userStore._distributor?.customerservice.trade_name || userStore._distributor.distributor_name
    }}</a
    >.
  </p>
  <p class="mt-4 mb-20 text-center" v-else>
    {{ $t("Questions about your tickets? Please contact") }}
    <a class="underline" :href="`mailto:support@xs2event.com`">{{ $t("our support team") }}</a
    >.
  </p>
</template>

<script setup>
import { useUserStore } from "@/store/user.js";
const userStore = useUserStore();
</script>
