<template>
  <form class="mt-3" @submit.prevent="saveTicketNotification()">
    <div class="flex w-full mt-3">
      <label for="ticketreminder" class="font-bold w-full">
        {{ $t("Send me an email as soon as my tickets become available") }}
        <input
          v-model="notificationEmail"
          placeholder="Email address..."
          type="email"
          class="focus:border-primary w-full border-2 mt-2 pl-2"
          required />
      </label>
    </div>

    <div class="flex w-full mt-3">
      <label for="ticketreminder" class="font-bold w-full">
        {{ $t("Confirm e-mail address") }}
        <input
          v-model="confirmEmail"
          placeholder="Email address..."
          type="email"
          class="focus:border-primary w-full border-2 mt-2 pl-2"
          required />
      </label>
    </div>

    <div class="flex w-full" v-if="!!errormsg">
      <span class="text-red-500">{{ errormsg }}</span>
    </div>

    <div class="flex w-full mt-3">
      <label for="acceptgeneralterms">
        <input id="acceptgeneralterms" type="checkbox" required />
        {{ $t("I accept the") }}
        <a class="underline" href="https://xs2event.com/privacy">{{ $t("Privacy Policy") }}</a>
      </label>
    </div>

    <div class="flex w-full">
      <button class="mr-4 mb-4 mt-4 rounded-md bg-primary px-4 py-2 text-btn-forecolor font-bold">
        {{ $t("Submit") }}
      </button>
    </div>
  </form>
</template>

<script setup>
import { useBookingsStore } from "@/store/bookings.js";
import { getLocale } from "../page/menu/locales.js";
import { ref } from "vue";

const props = defineProps({
  bookingorder: { required: true },
});

const notificationEmail = ref(null);
const confirmEmail = ref(null);
const errormsg = ref(null);
const bookingsStore = useBookingsStore();

const saveTicketNotification = () => {
  const payload = {
    bookingorderId: props.bookingorder?.bookingorder_id,
    email_address: notificationEmail.value,
    locale: getLocale(),
  };
  if (notificationEmail.value === confirmEmail.value) {
    bookingsStore
      .saveEmail(payload)
      .then(() => {
        location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  } else {
    errormsg.value = "Please make sure the e-mail addresses are the same";
  }
};
</script>
