import { useBrandStore } from "../store/brand";
import { computed, onMounted, watch } from "vue";
import { hexToHSL } from "../helpers/color";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";

export const useBrand = () => {
  const brandStore = useBrandStore();
  const { t } = useI18n();
  const route = useRoute();

  watch(
    () => route.params?.brand || route.params?.distributor || localStorage.brand,
    (value) => {
      if (!value) {
        return;
      }

      brandStore.fetchBrand(value);
    },
    { immediate: true }
  );

  //Set initial color based on loaded stylesheet
  const initialPrimaryColor = getComputedStyle(document.body).getPropertyValue("--twc-primary");

  //Set initial font based on loaded stylesheet
  const initialFontFamily = getComputedStyle(document.body).getPropertyValue("font-family");

  const primary = computed(() => {
    if (brandStore.data?.corporate_color) {
      const hsl = hexToHSL(brandStore.data.corporate_color);

      return `${hsl.h} ${hsl.l}% ${hsl.s}%`;
    }

    return initialPrimaryColor;
  });

  const fontFamily = computed(() => {
    if (brandStore.data?.font_family) {
      return `${brandStore.data.font_family}, sans-serif`;
    }
    return initialFontFamily;
  });

  const documentTitle = computed(() => {
    const titles = [];

    if (route.meta.title) {
      titles.push(t(route.meta.title));
    }

    if (brandStore.data?.distributor_name) {
      titles.push(brandStore.data.distributor_name);
    }

    return titles.join(" | ");
  });

  watch(
    documentTitle,
    (title) => {
      document.title = title;
    },
    { immediate: true }
  );

  return { primary, fontFamily };
};
