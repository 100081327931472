import { defineStore } from "pinia";
import { api } from "@/helpers/api.js";

const INITIAL_STATE = () => ({
  data: undefined,
});

export const useBrandStore = defineStore("theme", {
  state: INITIAL_STATE,
  actions: {
    fetchBrand(slug) {
      return api.get(`/distributors/slug/${slug}`).then((res) => {
        this.data = res.data;
      });
    },
  },
});
