import View404 from "@/pages/View404.vue";
import HomePage from "@/pages/HomePage.vue";
import { useUserStore, initializeUserStore } from "@/store/user";
import { isBranded } from "../brandconfig.js";

const ifAuthenticated = async (to, from, next) => {
  try {
    const userStore = useUserStore();
    await initializeUserStore();
    await userStore.isAuthenticated;
    if (!userStore.isAuthenticated) {
      return next({
        path: "/sign-in",
        query: { redirect: to.fullPath },
      });
    }
  } catch (err) {
    console.warn(err);
  }
  next();
};

export const routes = [
  {
    path: "/:brand?",
    component: HomePage,
    beforeEnter: ifAuthenticated,
    meta: { title: "Ticket" },
  },
  {
    path: "/:brand?/:code",
    component: HomePage,
    beforeEnter: ifAuthenticated,
    meta: { title: "Ticket" },
  },
  {
    path: "/:brand?/sign-in",
    component: () => import("@/pages/SignInPage.vue"),
    meta: { title: "Sign in" },
  },
  {
    path: "/sign-in/:code",
    component: () => import("@/pages/SignInPage.vue"),
    meta: { title: "Sign in" },
  },
  {
    path: "/:brand?/download/:code",
    component: () => import("@/pages/SignInPage.vue"),
    meta: { title: "Sign in" },
  },
  {
    path: "/:distributor/sign-in",
    component: () => import("@/pages/SignInPage.vue"),
    meta: { title: "Sign in" },
  },
  {
    path: "/:brand?/:notFound(.*)*",
    component: View404,
    meta: { title: "Page not found" },
  },
  { path: "/download/priority", redirect: "/" },
  { path: "/emesa", redirect: "/emesa/sign-in" },
  { path: "/download/emesa", redirect: "/emesa/sign-in" },
  { path: "/socialdeal", redirect: "/socialdeal/sign-in" },
  { path: "/download/socialdeal", redirect: "/socialdeal/sign-in" },
];
