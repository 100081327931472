import local from "./local.js";
import production from "./production.js";
import test from "./test.js";
import test_whitelist from "./test_whitelist.json";
import production_whitelist from "./production_whitelist.json";

export { local, test };
export const isTest =
  window.location.hostname.split(".").includes("test") || test_whitelist.whitelist.includes(window.location.hostname);
export const isProduction = !isTest && production_whitelist.whitelist.includes(window.location.hostname);
export const isLocal = !isTest && !isProduction;
export const config = isTest ? test : isProduction ? production : local;

export default config;
