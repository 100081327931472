<template>
  <ZipDownloadWarningModal :open="showZipDownloadModal" @download-zip-accepted="downloadTicketsZip()" />
  <div class="text-center" v-if="totalItems === 1">
    <p class="font-bold text-xl">{{ event?.eventName || "Loading..." }}</p>
    <p>{{ event?.tournamentName }} ({{ event?.season }})</p>
    <p v-if="event?.dateStart">
      {{ event?.venueName }}, {{ event?.city }},
      {{ formatDate(event?.dateStart) }}
      <span v-if="event.date_confirmed">{{ formatTime(event?.dateStart) }}</span>
      - {{ formatDate(event?.dateStop) }}
      <span v-if="event.dateConfirmed"> {{ formatTime(event?.dateStop) }}</span>
    </p>
  </div>

  <div class="flex w-full justify-center gap-2 mb-5" :key="index">
    <div
      class="flex flex-col w-full max-w-2xl mx-auto rounded overflow-hidden shadow-xl p-2 opacity-80 hover:opacity-100">
      <div
        class="flex w-full justify-between items-center text-xl text-bold p-4 cursor-pointer"
        @click="index > 0 ? openBoDetails(index) : ''">
        <div class="font-bold" v-if="totalItems > 1">
          {{ event?.eventName || "Loading..." }}
        </div>
        <div :id="`arrow-${index}`" :class="index > 0 ? `` : 'rotate-90'" class="ml-auto">
          <ChevronRightIcon v-if="totalItems > 1" class="w-5"></ChevronRightIcon>
        </div>
      </div>
      <div :class="index > 0 ? `p-2 hidden` : 'p-2'" :id="`bo-${index}`" class="px-4">
        <div class="grid grid-cols-2 w-full">
          <div v-if="totalItems > 1"> {{ event?.tournamentName }} ({{ event?.season }}) </div>
          <div v-else></div>
          <span
            v-if="allowToDownload() && bookingOrder.zip_sha"
            @click="downloadTicketsZipIntercept()"
            class="text-xs mb-2 sm:text-sm flex items-center gap-2 ml-auto hover:text-opacity-10 hover:underline">
            Download ZIP <DocumentCheckIcon class="text-primary h-6" />
          </span>
        </div>
        <div v-if="event?.date_start && totalItems > 1">
          {{ event?.venueName }}, {{ event?.city }} -
          {{ formatDate(event?.dateStart) }}
          <span v-if="event.dateConfirmed">{{ formatTime(event?.dateStart) }}</span>
        </div>
        <div class="pb-4">
          <div v-for="[key, value] in Object.entries(countTickets(bookingOrder.items))">
            {{ value }} &times; {{ key }}
          </div>
        </div>
        <a
          class="mb-4 flex items-center gap-2 hover:text-opacity-10 underline"
          href="#"
          v-if="categoryFiles.length > 0"
          @click="downloadFiles()">
          Download ticketinfo <FolderArrowDownIcon class="w-6 text-primary" />
        </a>
        <div v-if="!allowToDownload()">
          <p class="font-bold">
            {{ $t("Your tickets are not available yet!") }}
          </p>
          <p>
            {{ $t("Your e-tickets are expected to be available about") }}
            {{ categories[0]?.ticketDeliveryDays }}
            {{ $t("days prior to the event. Please come back later to download your e-tickets.") }}
          </p>
          <div v-if="isWaitfornotify()" class="mt-3">
            <p class="font-bold">
              {{ $t("We will send you an email as soon as your tickets are available!") }}
            </p>
          </div>
          <div v-else>
            <notifyForm :bookingorder="bookingOrder" />
          </div>
        </div>
        <div v-else-if="!ownDistributionChannel()" class="font-bold">
          {{ $t("You will receive the tickets for this order by an external distributor") }}.
        </div>
        <BookingorderItem
          v-else
          :item="item"
          :bookingOrderId="bookingOrder?.bookingorder_id"
          :index="idx"
          :allowDownload="allowToDownload()"
          :hasLinks="hasLinks"
          :physicalTickets="physicalTickets()"
          v-for="(item, idx) in bookingOrder?.items" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { BookingorderItem, ZipDownloadWarningModal, NotifyForm } from "@/components/xs2ticket";
import { useBookingsStore } from "@/store/bookings.js";
import { useUserStore } from "@/store/user.js";
import { useEventStore } from "@/store/event.js";
import { formatDate, formatTime } from "@/helpers/format";
import { onMounted, ref } from "vue";
import { ChevronRightIcon, DocumentCheckIcon, FolderArrowDownIcon } from "@heroicons/vue/24/solid";
import { useRoute, useRouter } from "vue-router";
import { TicketType, DistributionChannel } from "@xs2event/js-lib";
import { CategoryResponse, CategoryFileResponse } from "@xs2event/js-lib/api";
import { startDownload } from "@/helpers/download";

const router = useRouter();
const eventStore = useEventStore();
const bookingsStore = useBookingsStore();
const userStore = useUserStore();
const route = useRoute();
const event = ref(null);
const hasLinks = ref();
const { bookingOrder, index } = defineProps(["bookingOrder", "index", "totalItems"]);
const categoryFiles = ref<CategoryFileResponse[][]>([]);
const showZipDownloadModal = ref(false);

const getEvent = () => {
  eventStore.fetchEvent(bookingOrder?.event_id).then((eventRes) => {
    event.value = eventRes;
  });
};

const countTickets = (items) => {
  if (!!items) {
    return items.reduce(
      (acc, value) => ({
        ...acc,
        [value.ticket_name]:
          (acc[value.ticket_name] || 0) + (value.ticket_status !== "returned" && value.quantity > 0 ? 1 : 0),
      }),
      {}
    );
  }
};

onMounted(() => {
  getEvent();
  gatherItineraryFiles();
  hasLinks.value = !!bookingOrder.items[0]?.download_items[0]?.downloaditem_id;
});

const physicalTickets = () => {
  return bookingOrder.items.some((i) => {
    return (
      i.type_ticket === TicketType.PAPER ||
      i.type_ticket === TicketType.COLLECT ||
      i.type_ticket === TicketType.PAPERTICKET
    );
  });
};

const openBoDetails = (index: number) => {
  document.getElementById(`bo-${index}`).classList.toggle("hidden");
  document.getElementById(`arrow-${index}`).classList.toggle("rotate-90");
};

const allowToDownload = () => {
  let diff = (new Date(bookingOrder?.items[0]?.ticket_startdate).getTime() - new Date().getTime()) / 1000;
  diff /= 60 * 60 * 24;
  let daysBefore = diff.toFixed(2);
  return (
    (bookingOrder?.distributorfinancial_status === "paid" && bookingCompleted()) ||
    (bookingOrder?.distributorfinancial_status !== "paid" &&
      daysBefore <= userStore._distributor.grace_period_download_tickets &&
      bookingCompleted())
  );
};

const bookingCompleted = () => {
  return bookingOrder.logistic_status === "completed";
};

const ownDistributionChannel = () => {
  return bookingOrder.items.some((i) => i.distribution_channel === DistributionChannel.XS2EVENT);
};

const downloadTicketsZipIntercept = () => {
  showZipDownloadModal.value = true;
};

const downloadTicketsZip = () => {
  showZipDownloadModal.value = false;
  let zipFileName = route.params.code ? route.params.code : bookingOrder.bookingorder_id;
  const payload = {
    bookingOrderId: bookingOrder.bookingorder_id,
    fileName: zipFileName,
  };
  bookingsStore
    .downloadTicketsAsZip(payload)
    .then()
    .catch((e) => {
      console.log(e);
    });
};

const categories = ref<CategoryResponse[]>([]);

const gatherItineraryFiles = () => {
  const category_ids = new Set();

  bookingOrder.items.forEach((i) => {
    category_ids.add(i.category_id);
  });
  const unique_category_ids = [...category_ids];
  eventStore
    .getCategories(`in:[${unique_category_ids}]`)
    .then((res) => {
      categories.value = res.categories;
    })
    .then(() => {
      categories.value.forEach((c) => {
        c?.files.forEach((f) => {
          categoryFiles.value.push(f);
        });
      });
    });
};

const downloadFiles = () => {
  categoryFiles.value.forEach((cf) => {
    startDownload(
      `https://cdn.xs2event.com/category-files/${cf.category_id}/${cf.information_filename}`,
      cf.information_filename
    );
  });
};

const isWaitfornotify = () => bookingOrder?.notify_client_status === "waitfornotify";
</script>
