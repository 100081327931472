<template>
  <RouterView />
  <Toasts />
</template>
<script setup>
import { RouterView } from "vue-router";
import { inject } from "vue";
import { useUserStore } from "@/store/user";
import brandconfig from "@/brandconfig";
import { useBrand } from "@/composable/brand";
import { Toasts } from "@xs2event/js-lib/toast";
import { useVersionChecker } from "@/helpers/version.tsx";
const userStore = useUserStore();

useVersionChecker();

const { primary, fontFamily } = useBrand();

if (brandconfig.GA_ID) {
  inject("gtag");
}
</script>

<style scoped>
* {
  --twc-primary: v-bind(primary);
}

:first-child {
  font-family: v-bind(fontFamily);
}
</style>
