<template>
  <div class="w-full border-t border-dashed border-gray-400 pb-2 hover:bg-gray-100 p-2" :key="index">
    <div>
      <p class="font-bold text-md sm:text-sm mb-2"> {{ $t("Category") }} {{ item.category_name }} </p>
      <div class="w-full flex gap-2 items-baseline">
        <span v-if="item.section" class="text-xs text-secondary uppercase">{{ $t("Section") }} {{ item.section }}</span>
        <span class="flex items-baseline gap-1" v-if="item.row">
          <span class="text-xs text-secondary uppercase">{{ $t("Row") }}</span>
          <span class="font-semibold">{{ item.row }}</span>
        </span>
        <span v-if="item.row && item.seat"> / </span>
        <span class="flex items-baseline gap-1" v-if="item.seat">
          <span class="text-xs text-secondary uppercase">{{ $t("Seat") }}</span>
          <span class="font-semibold">{{ item.seat }}</span>
        </span>
      </div>
    </div>
    <div class="grid grid-cols-2 gap-2 mt-2 w-full">
      <div>
        <guest v-if="!!item.guest && Object.keys(item.guest).length" :guest="item.guest" />
      </div>
      <div class="ml-auto">
        <div v-if="physicalTickets && allowDownload" class="text-xs">
          {{ $t("This order contains physical tickets. We have sent you an email with more information") }}.
        </div>
        <div v-if="isReturnedorDeleted" class="text-xs"> {{ $t("This ticket has been returned or retrieved") }}. </div>
        <a v-else-if="allowDownload" href="#" class="text-xs sm:text-sm">
          <div v-if="hasLinks" v-for="link in item.download_items">
            <a
              v-if="link.external_activation_link"
              target="_blank"
              :href="link.external_activation_link"
              class="hover:underline px-2 py-1 rounded bg-primary hover:shadow text-sm font-medium flex gap-1 mt-1 items-center"
              >{{ $t("Open ticket") }}</a
            >
          </div>
          <div
            v-else
            @click="downloadSingleTicketInterceptor()"
            class="text-xs sm:text-sm text-xs flex items-center gap-2 ml-auto hover:text-opacity-10 hover:underline">
            Download PDF <DocumentArrowDownIcon class="h-6 float-right" />
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Guest } from "@/components/xs2ticket";
import { DocumentArrowDownIcon } from "@heroicons/vue/24/solid";
import { useBookingsStore } from "@/store/bookings";
import { TicketStatus } from "@xs2event/js-lib";
import { computed } from "vue";

const bookingsStore = useBookingsStore();
const { item, bookingOrderId, index, allowDownload, hasLinks, physicalTickets } = defineProps([
  "item",
  "bookingOrderId",
  "index",
  "allowDownload",
  "hasLinks",
  "physicalTickets",
]);

const downloadSingleTicketInterceptor = () => {
  const payload = {
    bookingorderId: bookingOrderId,
    url: item?.download_link,
    orderItemId: item?.orderitem_id,
    dayIdx: "",
  };
  bookingsStore.downloadSingleTicket(payload).then((res) => {
    if (!!res) {
      URL.revokeObjectURL(res);
    }
  });
};

const isReturnedorDeleted = computed(() => {
  return item.ticket_status === TicketStatus.RETURNED || !!item.deleted;
});
</script>
