import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import config from "@/config/index";

const uppercaseEnv = config.ENV.toUpperCase();

export const generateTransactionId = () => {
  try {
    let uuid = self.crypto.randomUUID();
    return uuid.replace(/-/g, "") + "_prt";
  } catch (e) {
    return Math.random().toString(36).substr(2, 9) + "_portal";
  }
};

export const applicationTransactionId = generateTransactionId();

export function initializeSentry(app, router) {
  if (config.SENTRY_ENABLE) {
    Sentry.init({
      app,
      release: config.VERSION,
      dist: config.VERSION,
      environment: uppercaseEnv,
      dsn: config.SENTRY_DSN,
      autoSessionTracking: true,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracePropagationTargets: ["localhost", "testapi.xs2event.com", "api.xs2event.com"],
        }),
      ],

      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 0.1,
    });
  }
}

export function setUserinSentryTracing(payload) {
  Sentry.setUser({
    username: payload.username ?? undefined,
    id: payload.client_id ?? undefined,
    distributor_name: payload.distributor_name ?? undefined,
    segment: payload.segment ?? undefined,
    roles: payload.roles ?? [],
  });
}

export function setTag(key, value) {
  Sentry.setTag(key, value);
}

// so you can search in sentry as well on this
setTag("sessionId", applicationTransactionId);
