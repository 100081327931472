<template>
  <div v-if="all_platforms">
    <PageHeaderEnv />
    <Transition>
      <div :lang="$i18n.locale" class="flex flex-col bg-background" :class="{ 'sm:pr-16': !isProduction }">
        <div class="flex flex-col h-full overflow-x-hidden sm:overflow-auto content">
          <div class="bg-header boundary border-b-8 border-primary">
            <slot name="header">
              <PageHeader />
            </slot>
          </div>
          <PageContent>
            <slot></slot>
          </PageContent>
          <slot name="extra-content"></slot>
          <!--          <slot name="footer">-->
          <!--            <PageFooter />-->
          <!--          </slot>-->
        </div>
      </div>
    </Transition>
  </div>
  <div
    v-else
    class="flex flex-col bg-background bg-white w-full h-full absolute flex justify-center items-center left-0 top-0"></div>
</template>

<script setup>
import brandconfig from "@/brandconfig.js";

import { isProduction } from "@/config";
import PageHeaderEnv from "./PageHeaderEnv.vue";
import PageHeader from "./PageHeader.vue";
import PageContent from "./PageContent.vue";
import PageFooter from "./PageFooter.vue";
import PageFooterBranded from "./PageFooterBranded.vue";
import { ref } from "vue";

const props = defineProps({
  path: { default: null },
  title: { default: null },
});

const all_platforms = ref(true);
const deviceType = ref("");
const userAgent = ref("");
const platform = navigator.userAgent.toLowerCase();

const icon = brandconfig.BRAND_ICON;
if (icon) {
  document.querySelector("link[rel=icon]").href = icon;
}
if (/(samsung|android|webos|iphone|blackberry|linux armv81|windows phone)/.test(platform)) {
  deviceType.value = "mobile";
} else if (/mac|win|linux/i.test(platform)) {
  deviceType.value = "desktop";
} else if (/tablet|ipad/i.test(platform)) {
  deviceType.value = "tablet";
} else {
  deviceType.value = "unknown";
}
userAgent.value = navigator.userAgent;
if (brandconfig.MOBILE_ONLY === true) {
  if (deviceType.value !== "mobile" && deviceType.value !== "tablet" && deviceType.value !== "unknown") {
    all_platforms.value = false;
  }
}
</script>
<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 250ms ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
