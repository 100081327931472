export default {
  BRAND: "xs2event",
  BRAND_TITLE: "XS2Event: B2B Ticketing platform",
  LOGO: null,
  HIGHLIGHT_LOGO: null,
  LOGO_MOBILE: null,
  LOGO_FOOTER: null,
  LANGUAGE_OPTION: true,
  CURRENCY_OPTION: true,
  MOBILE_ONLY: false,
  DEFAULT_LANGUAGE: "en",
  PRIVACY: "privacy-policy/",
  TERMS: "terms/",
  GENERAL_TERMS_LINK: "https://cdn.xs2event.com/generalterms/general-terms-XS2Event_v1.4.pdf",
  COMPANY_INFO: "about/",
  SPORT_TYPES: ["soccer", "motorsport", "rugby", "tennis", "motogp", "formula1"],
  GA_ID: "G-J6SHTJESVL",
};
