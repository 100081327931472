<template>
  <RouterLink to="/" class="text-forecolor flex items-center justify-center cursor-default">
    <div class="rounded-md overflow-hidden bg-mobile-overlay m-4 sm:m-0 sm:max-w-4xl flex flex-col cursor-pointer">
      <div class="relative text-white">
        <CoverPhoto class="brightness-75" src="not-found" alt="not found photo" />
        <div class="absolute top-4 left-4 text-white">
          <h3 class="text-sm sm:text-xl text-primary">
            {{ $t("PAGE NOT FOUND") }}
          </h3>
          <div class="text-xs font-bold">{{ route }}</div>
        </div>
        <XMarkIcon class="absolute top-4 right-4 h-7" />
      </div>
      <div class="grid p-4 gap-2">
        <p class="px-4">
          {{
            $t(
              "The page you were navigating to was not found. Maybe it has been moved or deleted. Our technical team has been notified and will look into this."
            )
          }}
        </p>
        <div class="ml-auto font-bold bg-primary rounded px-4 pt-2 pb-3">
          <span class="hover:underline"> {{ $t("Back to the homepage") }}</span>
        </div>
      </div>
    </div>
  </RouterLink>
</template>
<script setup>
import { CoverPhoto } from "@/components/not-found/";
import { RouterLink } from "vue-router";
import { XMarkIcon } from "@heroicons/vue/20/solid";

const route = window.location;
const path = [{ label: "Home", to: "/" }, { label: "Page not found" }];
</script>
