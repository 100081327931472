<template>
  <Transition>
    <img
      :src="mediaUrl(src)"
      :alt="altText"
      @error="(event) => event.target.error || (event.target.error = event.target.src = mediaUrl(placeholder))"
      class="pointer-events-none object-cover w-full group-hover:brightness-100 brightness-50 contrast-100 h-full" />
  </Transition>
</template>

<script setup>
import { config } from "@/config";

defineProps({
  src: { required: true },
  altText: { required: false },
  placeholder: { default: null },
});

const mediaBaseURL = config.EVENT_MEDIA_BASE_URL;
const mediaUrl = (src) => `${mediaBaseURL}/${src}`;
</script>
<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 250ms ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
