export const startDownload = (url, filename) => {
  const link = document.createElement("a");
  link.download = filename;
  if (!!filename) {
    link.download = filename;
  }
  link.href = url;
  link.target = "_blank";
  document.body.append(link);
  link.click();
};
