import { useI18n } from "vue-i18n";

const newDate = (datetime) => (datetime === "today" ? new Date() : new Date(datetime));

const toLocaleDateString = (datetime, format) => newDate(datetime).toLocaleDateString("en-EN", format);

export const formatISODate = (date) => {
  const d = new Date(date);

  const utc = new Date(0);
  utc.setUTCFullYear(d.getFullYear());
  utc.setUTCMonth(d.getMonth());
  utc.setUTCDate(d.getDate());

  return utc.toJSON().slice(0, 10);
};

export const todayISODate = formatISODate(new Date());

export const replaceToday = (str) => str.replace("TODAY", todayISODate);

export const formatDate = (datetime) =>
  toLocaleDateString(datetime, {
    year: "numeric",
    month: "short",
    day: "numeric",
  });

export const formatMonth = (datetime) =>
  toLocaleDateString(datetime, {
    month: "short",
    day: "numeric",
  });

export const formatDay = (datetime) => {
  const { t } = useI18n();
  const days = [t("Sunday"), t("Monday"), t("Tuesday"), t("Wednesday"), t("Thursday"), t("Friday"), t("Saturday")];
  const day = new Date(datetime).getDay();
  return days[day];
};

export const formatDays = (from, until) => {
  const s1 = formatDay(from);
  const s2 = formatDay(until);

  // Fri - Sun
  if (s1 !== s2) {
    return `${s1}—${s2}`;
  }

  // Sat
  return s1;
};

export const formatDates = (from, until) => {
  const [s1, m1, d1, y1] = formatDate(from).match(/(\w+) (\d+), (\d+)/);
  const [s2, m2, d2, y2] = formatDate(until).match(/(\w+) (\d+), (\d+)/);

  if (y1 !== y2) {
    // Dec 31, 2022 — Jan 1, 2023`
    return `${s1} — ${s2}`;
  }

  if (m1 !== m2) {
    // Jan 1-Feb 1, 2023
    return `${m1} ${d1}—${s2}`;
  }

  if (d1 !== d2) {
    // Jan 1-2, 2023
    return `${m1} ${d1}—${d2}, ${y2}`;
  }

  // Jan 1, 2023
  return s1;
};

// @todo verify
export const formatTime = (datetime) => (datetime.substr(11, 5) === "00:00" ? "" : datetime.substr(11, 5));

import __slugify from "slugify";
export const slugify = (str) => __slugify(str, { lower: true, strict: true });

export const formatEuros = (cents) => "€" + (cents / 100).toFixed(2);

export const capitalize = (str) => str.replace(/[a-z]/i, (ch) => ch.toUpperCase());

export const formatWithOffset = (datetime) => {
  const dt = new Date(datetime);
  let timeOffsetInMS = dt.getTimezoneOffset() * 60000;
  dt.setTime(dt.getTime() - timeOffsetInMS);
  return dt.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
};
