import { createRouter, createWebHistory } from "vue-router";
import { routes } from "@/pages";
import { todayISODate } from "@/helpers/format";

export const newRouter = () => {
  const history = createWebHistory();
  const router = createRouter({ routes, history });

  router.beforeEach((to, from) => {
    if (to.query.date_start === "today") {
      to.query.date_start = todayISODate;
      return to;
    }
  });

  return router;
};
