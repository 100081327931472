<template>
  <div class="nav-header bg-header w-full">
    <Disclosure
      as="nav"
      id="nav"
      class="bg-header h-15 p-2 sm:p-0 sm:h-auto sm:max-w-screen-xl sm:mx-auto"
      v-slot="{ open }"
      :key="searchMenuKey">
      <div class="custom-header p-4 px-2 sm:px-0">
        <div class="relative flex justify-between">
          <div class="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
            <div v-if="logo" class="hidden sm:flex flex-shrink-0 items-center">
              <RouterLink to="/"><img class="logo ml-2 pr-4 max-h-8" v-if="logo" :src="logo" alt="logo" /></RouterLink>
            </div>
            <div v-if="logo_mobile" class="flex sm:hidden z-50">
              <RouterLink to="/"><img class="logo" alt="logo" :src="logo_mobile" /></RouterLink>
            </div>
          </div>
          <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            <LanguageMenu />
            <button
              v-if="userStore.isAuthenticated"
              @click="logout()"
              class="relative ml-2 pl-2 border-l sm:pl-4 sm:flex-shrink-0">
              <span class="flex gap-2">
                <ArrowLeftEndOnRectangleIcon class="w-6 text-header-icon" />
              </span>
            </button>
          </div>
        </div>
      </div>
    </Disclosure>
  </div>
</template>

<script setup>
import { LanguageMenu } from "./menu";
import { computed } from "vue";
import { Disclosure } from "@headlessui/vue";
import { ArrowLeftEndOnRectangleIcon } from "@heroicons/vue/24/outline";

import brandconfig from "@/brandconfig.js";
import { onMounted, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useUserStore } from "@/store/user.js";
import { useBrandStore } from "../../store/brand.js";

const searchMenuKey = ref(0);
const userStore = useUserStore();
const router = useRouter();
const brandStore = useBrandStore();

const logo = computed(() => {
  return brandStore.data?.logo_url || brandconfig.LOGO;
});

const mobileLogo = computed(() => {
  return brandStore.data?.logo_url || brandconfig.LOGO_MOBILE;
});

const logout = () => {
  delete localStorage.bookingId;

  const brand = brandconfig.BRAND || brandStore.data?.slug || userStore.distributor_name;

  if (brand) {
    router.push(`${brand}/sign-in`).then(() => {
      userStore.signOut();
    });
  } else {
    router.push(`/sign-in`).then(() => {
      userStore.signOut();
    });
  }
};
</script>

<style scoped>
.logo {
  height: 30px;
  object-fit: contain;
}
</style>
