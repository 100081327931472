<template>
  <div>
    <div class="main flex relative flex-col w-full max-w-6xl mx-auto justify-start items-center p-4 pb-10">
      <div class="text-black flex flex-col justify-center items-center gap-2 rounded-md w-full mt-4">
        <div class="flex flex-col mb-8 w-full items-center">
          <h3 class="text-xl">
            {{ $t("Order overview") }}
            {{
              route.params.code
                ? route.params.code.toUpperCase()
                : bookingStore?.bookingorders[0]?.booking_code.toUpperCase()
            }}
          </h3>
          <h4 class="text-xs">{{ $t("Download your tickets") }}</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useUserStore } from "@/store/user.js";
import { useBookingsStore } from "@/store/bookings.js";
import brandconfig from "@/brandconfig.js";
import { useRouter, useRoute } from "vue-router";

const router = useRouter();
const route = useRoute();
const userStore = useUserStore();
const bookingStore = useBookingsStore();
const theme = brandconfig.BRAND;
</script>
